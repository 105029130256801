import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import Soon from "../features/TwoColWithButton";
import Typed from "react-typed";
import Footer from "../footers/MiniCenteredFooter";
import RevealCenter from "helpers/RevealCenter";
//import { css } from "styled-components/macro"; //eslint-disable-line

export default ({}) => {
  return (
    <RevealCenter>
      <Soon
        textOnLeft={false}
        imageDecoratorBlob={true}
        heading={
          <Typed
            loop
            typeSpeed={80}
            strings={[
              "Wij Zijn Bezig Met Onderhoud",
              "Our Site Is Under Construction",
            ]}
          />
          
        }
        subheading={""}
      />
      {/* <Footer showLogo={false} showLinks={false} topPadding={false}/> */}
    </RevealCenter>
  );
};
