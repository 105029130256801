import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "../headers/light.js";

import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
// import DesignIllustration from "../../images/calculatie.png";
import DesignIllustration from "../../images/designer-desk-2.svg";
import CustomersLogoStripImage from "../../images/customers-logo-strip.png";

const Container = tw.div`bg-primary-gray sm:bg-primary-red flex flex-col overflow-visible`;
const TwoColumn = tw.div`lg:px-24 max-h-[40rem] sm:max-h-full flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-8 md:pb-8 md:pt-6`;
const LeftColumn = styled.div`
  ${tw`lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left md:py-8`}
  span {
    ${tw`font-bold`}
  }
`;
const RightColumn = tw.div`mt-5 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`text-black sm:text-white font-light text-2xl md:text-3xl lg:text-[28px] leading-[1.2em]`;
const Paragraph = tw.p`my-2 lg:my-4 text-black sm:text-white text-base sm:text-lg leading-[22px]`;

const Actions = styled.div`
  ${tw`max-w-md text-center mx-auto lg:mx-0`}
  a {
    ${tw`min-h-[55px] px-[26px] border sm:absolute text-white mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center  sm:w-40 sm:leading-none focus:outline-none transition duration-300`}
  }
`;

const ButtonContainers = styled.div`
  ${tw`flex w-full items-center text-white flex-col md:flex-row mt-5 sm:mt-8`}
  a {
    ${tw`mx-4 my-2`}
  }
`;
const ContactButton = tw.button`text-sm 2xl:text-base min-w-[50%] font-medium sm:min-w-[12rem] justify-center cursor-pointer min-h-[55px] px-[26px] border border-primary-red sm:border-white text-primary-red sm:text-white rounded-full flex items-center  focus:outline-none transition duration-300`;
const SignUpButton =  tw.button`text-sm 2xl:text-base min-w-[50%] font-medium sm:min-w-[12rem] justify-center cursor-pointer min-h-[55px] px-[26px] text-white rounded-full flex items-center  focus:outline-none transition duration-300 hover:bg-primary-babyblue bg-green-600`;

const IllustrationContainer = tw.div`flex justify-center items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-[0.27] absolute left-[55px] top-[105px] h-64 w-64 transform -translate-x-2/3 -z-0`}
`;

const CustomersLogoStrip = styled.div`
  ${tw`mt-5 lg:mt-20`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`mt-4 w-full lg:pr-16 xl:pr-32 opacity-50`}
  }
`;

export default ({ roundedHeaderButton }) => {
  return (
    <>
      <Container className="hero--text">
        <TwoColumn>
          <LeftColumn>
            <Heading>
              <span>MHZ Finance! </span>
              We zijn behoorlijk goed met cijfers.
            </Heading>
            <Paragraph>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </Paragraph>
            <ButtonContainers>
              <ContactButton>Neem contact op</ContactButton>
              <a css={[tw`text-black sm:text-white`]}>of</a>
              <SignUpButton>Meld je direct aan</SignUpButton>
            </ButtonContainers>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img
                tw="min-w-0 w-[300px] max-w-[14rem] xs:max-w-lg xl:max-w-3xl"
                src={DesignIllustration}
                alt="Design Illustration"
              />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  );
};
